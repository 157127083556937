import { default as _91slug_93pURv82buVZMeta } from "/home/forge/baskabutik.net/pages/[slug].vue?macro=true";
import { default as addressIGwLo8eHgqMeta } from "/home/forge/baskabutik.net/pages/account/address.vue?macro=true";
import { default as _91id_93JTPfXZ8swjMeta } from "/home/forge/baskabutik.net/pages/account/orders/[id].vue?macro=true";
import { default as _91id_9360x1CPzRFGMeta } from "/home/forge/baskabutik.net/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexyQmoN8LFBqMeta } from "/home/forge/baskabutik.net/pages/account/orders/index.vue?macro=true";
import { default as reviewd9ZcIllU5gMeta } from "/home/forge/baskabutik.net/pages/account/review.vue?macro=true";
import { default as _91id_93Y2dDxO6330Meta } from "/home/forge/baskabutik.net/pages/account/statement/detail/[id].vue?macro=true";
import { default as indextMqLLlRpfPMeta } from "/home/forge/baskabutik.net/pages/account/statement/index.vue?macro=true";
import { default as userduuicS7VwuMeta } from "/home/forge/baskabutik.net/pages/account/user.vue?macro=true";
import { default as wishlistT0QcF0ELXGMeta } from "/home/forge/baskabutik.net/pages/account/wishlist.vue?macro=true";
import { default as cartANm0dGe0KLMeta } from "/home/forge/baskabutik.net/pages/cart.vue?macro=true";
import { default as indexwwZ6kUU9oZMeta } from "/home/forge/baskabutik.net/pages/checkout/index.vue?macro=true";
import { default as paymentrJ7FgR1tsoMeta } from "/home/forge/baskabutik.net/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientgAa8M3ROnWMeta } from "/home/forge/baskabutik.net/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/baskabutik.net/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestExgWCnJ8FXMeta } from "/home/forge/baskabutik.net/pages/customer/request.vue?macro=true";
import { default as success8xhR0C9JftMeta } from "/home/forge/baskabutik.net/pages/customer/success.vue?macro=true";
import { default as iletisimPNSc7EYHjIMeta } from "/home/forge/baskabutik.net/pages/iletisim.vue?macro=true";
import { default as indexLYqJuYKLKdMeta } from "/home/forge/baskabutik.net/pages/index.vue?macro=true";
import { default as login9hBCJQBbpMMeta } from "/home/forge/baskabutik.net/pages/login.vue?macro=true";
import { default as offerSPkCbotekZMeta } from "/home/forge/baskabutik.net/pages/offer.vue?macro=true";
import { default as statusKjbsdYFsqSMeta } from "/home/forge/baskabutik.net/pages/order/status.vue?macro=true";
import { default as trackQ1gDrJvhbxMeta } from "/home/forge/baskabutik.net/pages/order/track.vue?macro=true";
import { default as forgotjmE4wuX7UvMeta } from "/home/forge/baskabutik.net/pages/password/forgot.vue?macro=true";
import { default as resetjvhVSIjOJ8Meta } from "/home/forge/baskabutik.net/pages/password/reset.vue?macro=true";
import { default as payment_45notificationETMhaZbOhjMeta } from "/home/forge/baskabutik.net/pages/payment-notification.vue?macro=true";
import { default as summaryrdpUtiL2pyMeta } from "/home/forge/baskabutik.net/pages/payment/summary.vue?macro=true";
import { default as register3iNUqVTArXMeta } from "/home/forge/baskabutik.net/pages/register.vue?macro=true";
import { default as searchkaszdx6F6WMeta } from "/home/forge/baskabutik.net/pages/search.vue?macro=true";
import { default as locationsuKHTjzTy1mMeta } from "/home/forge/baskabutik.net/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stubOFzDdytun6Meta } from "/home/forge/baskabutik.net/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubOFzDdytun6 } from "/home/forge/baskabutik.net/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93pURv82buVZMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressIGwLo8eHgqMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93JTPfXZ8swjMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_9360x1CPzRFGMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexyQmoN8LFBqMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewd9ZcIllU5gMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93Y2dDxO6330Meta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indextMqLLlRpfPMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userduuicS7VwuMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistT0QcF0ELXGMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/baskabutik.net/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexwwZ6kUU9oZMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentrJ7FgR1tsoMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/baskabutik.net/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/baskabutik.net/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/baskabutik.net/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/baskabutik.net/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexLYqJuYKLKdMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: login9hBCJQBbpMMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerSPkCbotekZMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/baskabutik.net/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/baskabutik.net/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotjmE4wuX7UvMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetjvhVSIjOJ8Meta || {},
    component: () => import("/home/forge/baskabutik.net/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/baskabutik.net/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/baskabutik.net/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: register3iNUqVTArXMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchkaszdx6F6WMeta || {},
    component: () => import("/home/forge/baskabutik.net/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/baskabutik.net/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stubOFzDdytun6Meta?.name,
    path: "/index.php",
    component: component_45stubOFzDdytun6
  }
]